import { Field } from 'formik';
import { bool, func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';

import './textarea.scss';

const bem = new BEMHelper({
  name: 'form-textarea',
});

export const Textarea = ({
  id,
  label,
  name,
  value,
  required,
  disabled,
  readonly,
  error,
  onFocus,
  onBlur,
}) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback((event) => {
    setFocused(true);
    onFocus(event);
  }, [setFocused, onFocus]);
  const handleBlur = useCallback((event) => {
    setFocused(false);
    onBlur(event);
  }, [setFocused, onBlur]);

  return (
    <label
      {...bem(null, {
        'is-focused': focused,
        'is-disabled': disabled,
        'has-error': error,
      })}
      htmlFor={id}
    >
      <span {...bem('label')}>{label}</span>
      <Field
        {...bem('field')}
        as="textarea"
        rows="6"
        id={id}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        aria-describedby={error ? `${id}-error` : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {error && (
        <small
          {...bem('error')}
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </small>
      )}
    </label>
  );
};

Textarea.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  value: string,
  required: bool,
  disabled: bool,
  readonly: bool,
  error: string,
  onFocus: func,
  onBlur: func,
};

Textarea.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  readonly: false,
  error: undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
};
