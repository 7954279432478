import { Field } from 'formik';
import {
  array, bool, func, number, oneOfType, string,
} from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';

import './select.scss';

const bem = new BEMHelper({
  name: 'form-input',
});

export const Select = ({
  id,
  label,
  name,
  options,
  defaultValue,
  required,
  disabled,
  readonly,
  error,
  onFocus,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback((event) => {
    setFocused(true);
    onFocus(event);
  }, [setFocused, onFocus]);
  const handleBlur = useCallback((event) => {
    setFocused(false);
    onBlur(event);
  }, [setFocused, onBlur]);

  return (
    <div
      {...bem(null, {
        'is-focused': focused,
        'is-disabled': disabled,
        'is-readonly': readonly,
        'has-error': error,
        'required' : required
      })}
      htmlFor={id}
    >
      <label {...bem('label')}>{label}</label>
      <Field
        {...bem('select')}
        id={id}
        name={name}
        as="select"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      >
        <option value="">{defaultValue}</option>
        { options.map((element) => <option value={element} key={element}>{element}</option>) }
      </Field>
      {error && (
        <small
          {...bem('error')}
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </small>
      )}
    </div>
  );
};

Select.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  value: oneOfType([number, string]),
  options: array.isRequired,
  defaultValue: string.isRequired,
  required: bool,
  disabled: bool,
  readonly: bool,
  error: string,
  onFocus: func,
  onBlur: func,
};

Select.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  readonly: false,
  error: undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
};
