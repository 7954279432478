import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { usePolyfill } from './usePolyfill';

export const useIntersectionObserver = (settings) => {
  const [hasIO, setObserver] = useState(false);
  const test = useCallback(() => typeof window.IntersectionObserver !== 'undefined');
  const load = useCallback(() => import(/* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer'));
  const callback = useCallback(() => setObserver(true), [setObserver]);
  usePolyfill(test, load, callback);

  const [ref, ...rest] = useInView(settings);
  if (hasIO) {
    return [ref, ...rest];
  }

  return [null, false];
};
