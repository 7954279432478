import { Field } from 'formik';
import { bool, func, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { childrenType } from '../../../../types/children';
import './checkbox.scss';

const bem = new BEMHelper({
  name: 'form-checkbox',
});

export const Checkbox = ({
  id,
  children,
  name,
  value,
  required,
  disabled,
  readonly,
  error,
  onFocus,
  onBlur,
  className,
  ...props
}) => (
  <label
    {...bem(null, {
      'is-disabled': disabled,
      'has-error': error,
    }, className)}
    htmlFor={id}
  >
    <Field
      {...bem('field')}
      id={id}
      type="checkbox"
      name={name}
      value={value}
      required={required}
      disabled={disabled}
      readOnly={readonly}
      aria-describedby={error ? `${id}-error` : undefined}
      {...props}
    />
    <span {...bem('label')}>
      {children}
      {error && (
        <small
          {...bem('error')}
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </small>
      )}
    </span>
  </label>
);

Checkbox.propTypes = {
  id: string.isRequired,
  children: childrenType.isRequired,
  name: string.isRequired,
  value: string,
  required: bool,
  disabled: bool,
  readonly: bool,
  error: string,
  onFocus: func,
  onBlur: func,
  className: string,
};

Checkbox.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  readonly: false,
  error: undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
  className: null,
};
