import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { childrenType } from '../../../../types';
import { Content } from '../../content';
import './layout.scss';

const bem = new BEMHelper({
  name: 'form-layout',
});

export const Details = ({ headline, description, children }) => (
  <div {...bem('details')}>
    {headline && (<h2 {...bem('headline')}>{headline}</h2>)}
    {description && (<p {...bem('description')}>{description}</p>)}
    {children}
  </div>
);

Details.propTypes = {
  headline: string,
  description: string,
  children: childrenType,
};

Details.defaultProps = {
  headline: null,
  description: null,
  children: null,
};

export const Form = ({ children }) => (
  <div {...bem('form')}>{children}</div>
);

Form.propTypes = {
  children: childrenType.isRequired,
};

export const Layout = ({ children }) => (
  <Content {...bem()}>{children}</Content>
);

Layout.propTypes = {
  children: childrenType.isRequired,
};
