import clsx from 'clsx';
import {
  arrayOf, bool, number, string,
} from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { childrenType } from '../../../types';
import './content.scss';

export const Content = ({
  id,
  className,
  children,
  useOwnSpacing,
  observe,
  triggerOnce,
  threshold,
  ...props
}) => {
  const allClassNames = className.split(' ');
  const name = allClassNames[0];
  const modifier = allClassNames.slice(1).join(' ');
  const bem = new BEMHelper({ name });
  const [ref, isVisible] = useIntersectionObserver({ triggerOnce, threshold });

  return (
    <div
      id={id && `section-${id}`}
      {...bem(
        null,
        { 'is-visible': isVisible },
        clsx(
          modifier && modifier.trim(),
          'content',
          { 'content--use-default-spacing': !useOwnSpacing },
        ),
      )}
      {...props}
      ref={observe ? ref : null}
    >
      <div {...bem('contents')}>
        {children}
      </div>
    </div>
  );
};

Content.propTypes = {
  id: string,
  className: string.isRequired,
  children: childrenType.isRequired,
  useOwnSpacing: bool,
  observe: bool,
  triggerOnce: bool,
  threshold: arrayOf(number),
};

Content.defaultProps = {
  id: undefined,
  useOwnSpacing: false,
  observe: false,
  triggerOnce: true,
  threshold: [0.33],
};
