import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  arrayOf, number, string, objectOf,
} from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Helmet } from 'react-helmet';

const bem = new BEMHelper({ name: 'picture' });

export const fluidFragment = graphql`
  fragment Picture on CraftGQL_assets_Asset {
    alt: assetAlt
    width
    height
    url
    mimeType
  }

  fragment FluidPicture on CraftGQL_assets_Asset {
    ...Picture
    fluid_0: url(width: 480, mode: "crop", position: "")
    fluid_1: url(width: 640, mode: "crop", position: "")
    fluid_2: url(width: 768, mode: "crop", position: "")
    fluid_3: url(width: 1024, mode: "crop", position: "")
    fluid_4: url(width: 1280, mode: "crop", position: "")
  }
`;

const FLUID_WIDTHS = [480, 640, 768, 1024, 1280];

const getHostUrl = (fullUrl) => {
  const url = new URL(fullUrl);
  return url.origin;
};

export const FluidPicture = ({
  alt, url, mimeType,
  widths, width, height,
  className, role, imgStyle, maxWidth,
  ...props
}) => {
  const aspectRatio = width / height;
  const { fluid_0: fluid0 } = props;

  if (mimeType === 'image/svg+xml' || !fluid0) {
    const svgURL = getHostUrl(url);

    return (
      <>
        <Helmet>
          {/* dns-prefetch/preconnect */}
          <link rel="dns-prefetch" href={svgURL} />
          <link rel="preconnect" href={svgURL} />
        </Helmet>
        <div {...bem(null, null, className)} role={role}>
          <img
            src={url}
            alt={alt || ''}
            loading="lazy"
            width={width}
            height={height}
          />
        </div>
      </>
    );
  }

  const max = maxWidth || widths[widths.length - 1];

  const sizes = `(max-width: ${max}px) 100vw, ${max}px`;
  const sources = Object.entries(props).filter(([key]) => key.includes('fluid_'));

  const src = sources[sources.length - 1][1];
  const srcSet = sources.map(([, value], index) => `${value} ${widths[index]}w`).join(', ');

  const fluid = {
    aspectRatio,
    sizes,
    src,
    srcSet,
  };
  const fluidURL = getHostUrl(src);

  return (
    <>
      <Helmet>
        {/* dns-prefetch/preconnect */}
        <link rel="dns-prefetch" href={fluidURL} />
        <link rel="preconnect" href={fluidURL} />
      </Helmet>
      <div {...bem(null, null, className)} role={role}>
        <Img
          {...bem('viewport')}
          alt={alt || ''}
          fluid={fluid}
          imgStyle={imgStyle}
          {...props}
        />
      </div>
    </>
  );
};

FluidPicture.propTypes = {
  alt: string,
  url: string.isRequired,
  mimeType: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  fluid_0: string,
  imgStyle: objectOf(string),
  className: string,
  widths: arrayOf(number),
  role: string,
  maxWidth: number,
};

FluidPicture.defaultProps = {
  alt: '',
  fluid_0: null,
  widths: FLUID_WIDTHS,
  imgStyle: {},
  className: undefined,
  role: undefined,
  maxWidth: undefined,
};
