import { Field } from 'formik';
import {
  bool, func, number, oneOf, oneOfType, string,
} from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';

import './input.scss';

const NO_SCROLL = (event) => event.currentTarget.blur();

const bem = new BEMHelper({
  name: 'form-input',
});

export const Input = ({
  id,
  label,
  as,
  type,
  name,
  value,
  required,
  disabled,
  readonly,
  error,
  onFocus,
  onBlur,
  halfSize,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback((event) => {
    setFocused(true);
    onFocus(event);
  }, [setFocused, onFocus]);
  const handleBlur = useCallback((event) => {
    setFocused(false);
    onBlur(event);
  }, [setFocused, onBlur]);

  return (
    <label
      {...bem(null, {
        'is-focused': focused,
        'is-disabled': disabled,
        'is-readonly': readonly,
        'has-error': error,
        'is-fifty' : halfSize
      })}
      htmlFor={id}
    >
      <span {...bem('label')}>{label}</span>
      <Field
        {...bem('field')}
        id={id}
        as={as}
        type={type}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        aria-describedby={error ? `${id}-error` : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onWheel={NO_SCROLL}
        {...props}
      />
      {error && (
        <small
          {...bem('error')}
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </small>
      )}
    </label>
  );
};

Input.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  type: oneOf(['text', 'number', 'password', 'email', 'tel']).isRequired,
  value: oneOfType([number, string]),
  required: bool,
  disabled: bool,
  readonly: bool,
  error: string,
  onFocus: func,
  onBlur: func,
};

Input.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  readonly: false,
  error: undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
};
