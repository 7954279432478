import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {
  bool, func, number, oneOfType, string,
} from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';

import '../select/select.scss';

const bem = new BEMHelper({
  name: 'form-input',
});

export const Countryselect = ({
  id,
  label,
  name,
  value,
  region,
  options,
  defaultValue,
  required,
  disabled,
  readonly,
  error,
  onFocus,
  handleChange,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback((event) => {
    setFocused(true);
    onFocus(event);
  }, [setFocused, onFocus]);

  const handleBlur = useCallback((event) => {
    console.log("handleBlur")
    setFocused(false);
    onBlur(event);
  }, [setFocused, onBlur]);

  return (
    <div
      {...bem(null, {
        'is-focused': focused,
        'is-disabled': disabled,
        'is-readonly': readonly,
        'has-error': error,
        'required' : required
      })}
      htmlFor={id}
    >
      <label {...bem('label')}>{label}</label>
      <CountryDropdown
        {...bem('select')}
        id={id}
        name={name}
        value={value}
        valueType="short"
        priorityOptions={['DE', 'GB', 'US']}
        onChange={(_, e) => handleChange(e)}
        onFocus={handleFocus}
        onBlur={(_, e) => handleBlur(e)}
        {...props}
      />
      {/*<RegionDropdown*/}
      {/*  disableWhenEmpty={true}*/}
      {/*  country={value}*/}
      {/*  value={region}*/}
      {/*  countryValueType="short"*/}
      {/*  name="state_code"*/}
      {/*  onChange={(_, e) => handleChange(e)} />*/}
      {error && (
        <small
          {...bem('error')}
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </small>
      )}
    </div>
  );
};

Countryselect.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  value: oneOfType([number, string]),
  required: bool,
  disabled: bool,
  readonly: bool,
  error: string,
  handleChange: func,
  onFocus: func,
  onBlur: func,
};

Countryselect.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  readonly: false,
  error: undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
  handleChange: () => undefined,
};
